<template>
  <el-dialog
    :visible.sync="modalVisible"
    :before-close="beforeClose"
    :close-on-click-modal="false"
    :title="title"
    width="480px"
    append-to-body
  >
    <el-upload
      action=""
      accept="image/*"
      drag
      ref="Uploader"

      v-loading="loading"
      :element-loading-text="loadingMsg"
      :show-file-list="false"
      :before-upload="handleBeforeUpload"
    >
      <i class="el-icon-upload"></i>
      <div class="el-upload__text"><em>点击上传 / 拖拽图片 / 粘贴图片</em>到这里，自动开始识别</div>
      <div class="el-upload__text ocr-tip">支持图片格式: PNG、JPG，图片大小不能超过{{ maxSize }}MB。</div>
      <!--<div class="el-upload__tip" slot="tip">只能上传jpg/png文件，且不超过500kb</div>-->
    </el-upload>
  </el-dialog>
</template>

<script>
import ossClient from '@/components/Uploader/ossClient'
import { SETTING } from '@/config/setting'
import { imageToLatex } from '@/api/common'

export default {
  name: 'EditorOCR',
  props: {
    visible: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '.jpg, .jpeg, .png'
    },
    maxSize: {
      type: Number,
      default: 10, // 上传文件大小限制 默认10M
    },
    title: {
      type: String,
      default: 'OCR识别 - 文本提取工具'
    },
  },
  data () {
    return {
      loading: false,
      loadingMsg: undefined,
      modalVisible: false,
      actionId: 0, // 异步锁
      callback: null,
    }
  },
  watch: {
    visible: {
      immediate: true,
      handler (val) {
        if (val !== this.modalVisible) {
          this.modalVisible = val
        }
      }
    },
    modalVisible: {
      immediate: true,
      handler (val) {
        if (val) {
          this.$nextTick(() => {
            this.$refs.Uploader.$el.querySelector('.el-upload').focus()
          })
        }
      }
    }
  },
  beforeDestroy () {
    document.removeEventListener('paste', this.pasteAction)
  },
  created () {
    document.addEventListener('paste', this.pasteAction)
  },
  methods: {
    show (callback) {
      this.callback = callback
      this.modalVisible = true
      this.$emit('update:visible', true)
    },
    close (done) {
      this.actionId += 1
      done ? done() : this.modalVisible = false
      this.$emit('update:visible', false)
    },

    beforeClose (done) {
      this.loading
        ? this.$confirm('OCR识别中，请确认退出').then(() => {
          this.close(done)
        })
        : this.close(done)
    },

    // 上传校验
    handleBeforeUpload (file) {
      const whiteList = this.accept.split(',').map(m => m.trim().toLowerCase())
      const idx = file.name.lastIndexOf('.')
      const extension = idx > 0 ? file.name.substring(idx).toLowerCase() : ''
      if (!(extension && whiteList.includes(extension))) {
        this.$message.warning('请上传正确格式的文件')
        return
      }

      if (file.size > this.maxSize * 1024 * 1024) {
        this.$message.warning(`图片大小不能超过${this.maxSize}MB`)
        return
      }

      const callback = this.callback
      callback && this.getImageData(file).then(data => {
        const filePath = `tmp/${this.$common.getMyd()}/${this.$common.getUuid()}${extension}`

        const actionId = this.actionId
        this.loadingMsg = '上传中...'
        this.loading = true
        ossClient.put(filePath, file)
          .then(() => {
            if (actionId !== this.actionId) return
            this.loadingMsg = 'OCR识别中，请稍等'
            return imageToLatex([{
              imageUrl: SETTING.URL.IMAGE_URL + '/' + filePath,
              x_min: 0,
              y_min: 0,
              x_max: data.width,
              y_max: data.height,
            }])
          })
          .then(res => {
            if (actionId !== this.actionId) { return }
            if (res.code === 0) {
              if (res.data) {
                callback(res.data)
                this.close()
              }
            } else {
              this.$message.error(res.msg)
            }
          })
          .catch(e => {
            this.$message.error('OCR识别失败')
          })
          .finally(() => {
            this.loading = false
          })
      })
      return false
    },

    // 获取图片信息(宽、高)
    getImageData (file) {
      return new Promise((resolve, reject) => {
        const loadErr = err => {
          this.$message.error('图片加载失败')
          reject(err)
        }
        const reader = new FileReader()
        reader.onload = event => {
          const image = new Image()
          image.onload = () => {
            resolve({
              height: image.naturalHeight,
              width: image.naturalWidth,
            })
          }
          image.error = loadErr
          image.src = event.target.result
        }
        reader.onerror = loadErr
        reader.readAsDataURL(file)
      })
    },

    // 粘贴事件监听
    pasteAction (event) {
      if (this.modalVisible) {
        const data = event.clipboardData || window.clipboardData
        if (data.files.length) {
          this.handleBeforeUpload(data.files[0])
        }
      }
    },
  }
}
</script>

<style lang="scss" scoped>
/deep/ .el-upload {
  display: block;
  margin-top: -20px;

  .el-upload-dragger {
    width: 100%;
    height: 160px;
  }

  .el-icon-upload {
    font-size: 54px;
    margin: 30px 0 10px;
  }

  .el-upload__text {
    user-select: none;
  }
  .ocr-tip {
    margin-top: 8px;
    font-size: 12px;
    color: #999;
  }
}
</style>
